
import CancelEnrollment from '@/components/CancelEnrollment.vue'
import Student from '@/types/student'
import { defineComponent } from 'vue'

export default defineComponent({
  components: { CancelEnrollment },
  data () {
    return {
      students: [] as Student[]
    }
  },
  async mounted () {
    const response = await this.$api.getStudents()
    if (response.students) {
      this.students = response.students
    }
  },
  methods: {
    goToEnroll () {
      this.$router.replace({ name: 'enroll_students' })
    },
    classSelected () {
      return this.$store.getters.selectedClass
    }
  }
})
