<template>
  <div class="container mt-3">
    <h4 class="mb-3">Students</h4>
    <div>
      <strong v-if="students.length < 1">Add a student to enroll.</strong>
      <div class="list-group">
        <div class="list-group-item" v-for="student in students" :key="student.id">
          <div class="d-flex justify-content-between align-items-center">
            <h5>{{ student.firstName }}</h5>
            <router-link class="btn btn-primary" :to="{ name: 'student_edit', params: { id: student.id } }">View/edit details</router-link>
          </div>
        </div>
      </div>
      <router-link class="btn btn-success mt-3" :to="{ name: 'student_edit', params: { id: 'new' } }">Add a student</router-link>
      <div class="alert alert-info mt-3" v-if="classSelected()">If you have added the student(s) you want to enroll, click the 'continue enrollment' button.</div>
    </div>
    <div class="d-flex align-items-center mt-5">
      <button class="btn mb-3 btn-primary me-3" v-if="classSelected()" @click="goToEnroll()">Continue enrollment</button>
      <cancel-enrollment />
    </div>
  </div>
</template>

<script lang="ts">
import CancelEnrollment from '@/components/CancelEnrollment.vue'
import Student from '@/types/student'
import { defineComponent } from 'vue'

export default defineComponent({
  components: { CancelEnrollment },
  data () {
    return {
      students: [] as Student[]
    }
  },
  async mounted () {
    const response = await this.$api.getStudents()
    if (response.students) {
      this.students = response.students
    }
  },
  methods: {
    goToEnroll () {
      this.$router.replace({ name: 'enroll_students' })
    },
    classSelected () {
      return this.$store.getters.selectedClass
    }
  }
})
</script>
